//--- Font size ---//
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;
//Font Weight
$fw-semi-bold:                    700;
$fw-bold:                         800;
$fw-light:                        300;
$fw-normal:                       400;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

// stylelint-disable
$white:                                  #fff !default;
$gray-100:                               #FAFAFA !default;
$gray-200:                               #F4F7FA !default; //body background
$gray-300:                               #EBEDF2 !default;
$gray-400:                               #F5F9FB !default;
$gray-500:                               #DEE4E8 !default;
$gray-600:                               #727891 !default;
$gray-700:                               #A5A7B2 !default;
$gray-800:                               #677080 !default;
$gray-900:                               #464D69 !default;
$black:                                  #000 !default;

.font-lg {
  font-size: $font-size-lg;
}
.font-md {
  font-size: ($font-size-sm + 0.125rem);
}
.font-sm {
  font-size: $font-size-sm !important;
}
.font-xs {
  font-size: 0.75rem !important;
  line-height:1.25rem;
}
.font-2x {
  font-size: 2rem;
}
.font-3x {
  font-size: 3rem;
}
.font-4x {
  font-size: 4rem !important;
}
.font-5x {
  font-size: 5rem;
}
.font-xl {
  font-size: 2.625rem; //42px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-23 {
  font-size: 23px;
}
.fs-24 {
  font-size: 24px;
}
.fs-50 {
  font-size: 50px;
}
//Font weight
.fw-light{
   font-weight:300 !important;
}
.fw-normal {
  font-weight: $fw-normal !important;
}
.fw-semi-bold {
  font-weight: $fw-semi-bold !important;
}
.fw-bold {
  font-weight: $fw-bold !important;
}
// Font Text
.text-black {
  color: black !important;
}
.text-red {
	color: $red !important;
}
.text-pink {
	color: $pink !important;
}
.text-indigo {
	color: $indigo !important;
}
.text-purple {
	color: $purple !important;
}
.text-yellow {
	color: $orange !important;
}
.text-base {
  color: #A5A7B2;
}
.text-gray {
  color: $gray-800;
}
.text-main {
  color: #1160aa !important;
}
.text-teal {
  color: #1F95C8 !important;
}
.text-desc {
  color: #333333 !important;
}
.text-desc1 {
  color: #666666 !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 767px) {

  .fs-12 {
    font-size: 12px;
  }
  .fs-13 {
    font-size: 13px;
  }
  .fs-14 {
    font-size: 13px !important;
  }
  .fs-16 {
    font-size: 15px;
  }
  .fs-17 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 16px;
  }
  .fs-20 {
    font-size: 14px !important;
  }
  .fs-21 {
    font-size: 14px !important;
  }
  .fs-24 {
    font-size: 16px;
  }
  .fs-50 {
    font-size: 50px;
  }
}