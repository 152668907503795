//bootstrap
$border-color: #dddddd;

body {
  font-family: 'Nanum Gothic', sans-serif;

  button {
    span {
      font-family: 'Nanum Gothic', sans-serif !important;
    }
  }
}

#root {
  overflow: auto;
}

.site-header {
  .header-info {
    max-width: 1300px;
    margin: 0 auto;
    padding: 17px 50px;
    justify-content: space-between;
    align-content: center;

    .header-icons {
      img {
        width: 40px;
        height: 40px;
        margin: 0 8px;
      }
    }

    .header-logo {
      width: 180px;
    }

    .header-user {
      align-items: center;

      span {
        margin: 0 10px;
        font-size: 14px;
        color: #888888;
        cursor: pointer;
      }

      .divider {
        width: 2px;
        background-color: #dddddd;
        height: 13px;
      }
    }
  }

  .header-menu {
    box-shadow: 0 2px 5px #cccccc;

    & > div {
      max-width: 1300px;
      padding: 0 50px;
      margin: 0 auto;
      justify-content: space-between;
    }

    span {
      width: 130px;
      padding: 10px 0;
      font-size: 19px;
      font-weight: bold;
      color: black;
      cursor: pointer;
      text-align: center;
      //text-shadow: 1px 1px 2px #888888;

      //&:hover {
      //  background-color: #d1e4f6;
      //}

      &.active {
        color: white;
        background-color: #1160AA;
      }
    }
  }
}

.site-mobile-header {
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: white;

  .header-bar {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 10px;
    box-shadow: 0 2px 5px #cccccc;
  }

  .m-header-content {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    background-color: white;
    z-index: 3;

    &.hide {
      display: none;
    }

    .m-header-top {
      padding: 30px;
      background-color: #1f95c8;
      color: white;

      .m-header-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;

        div {
          margin-right: 10px;
          background-color: #1160aa;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      .divider {
        width: 1px;
        background-color: white;
        height: 13px;
      }
    }

    .m-header-menu {
      flex: 1;
      display: flex;
      flex-direction: column;

      span {
        padding: 20px 30px;
        font-size: 14px;
        color: black;
        border-bottom: 1px solid #dddddd;
        cursor: pointer;
      }
    }

    .m-header-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 50px 80px;
      background-color: #eaedf0;
    }
  }
}

.site-footer {
  border-top: 1px solid #dddddd;

  & > div {
    max-width: 1300px;
    margin: 0 auto;
    padding: 70px 50px 40px;
  }

  .footer-logo {
    width: 200px;
  }

  .footer-info {
    flex: 1;
    color: #999999;

    .footer-line1 {
      align-items: center;
      color: #666666;

      span {
        cursor: pointer;
      }

      .divider {
        width: 2px;
        height: 13px;
        margin: 0 15px;
        background-color: #dddddd;
      }
    }
  }
}

.page-container {
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }

  input {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .MuiSelect-select {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  @media screen {

  }
}

.page-center {
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  min-height: 400px;
  padding: 0 50px;
  margin: 0 auto;
}

.page-content {
  flex: 1;
  min-width: 0;
  padding: 90px 0 90px 50px;
}

@media (max-width: 767px) {
  .page-container {
    padding-top: 50px;
  }
  .site-footer {
    & > div {
      padding: 30px 10px;
    }

    .footer-logo {
      width: 150px;
    }
  }
  .page-center {
    flex-direction: column;
    margin: 0;
    padding: 10px;
  }
  .page-content {
    padding: 15px 10px;
  }
}

//  /* Media Query for Mobile Devices */
//  @media (max-width: 480px) {
//  }
//
//  /* Media Query for low resolution  Tablets, Ipads */
//  @media (min-width: 481px) and (max-width: 767px) {
//  }
//
//  /* Media Query for Tablets Ipads portrait mode */
//  @media (min-width: 768px) and (max-width: 1024px){
//  }
//
//  /* Media Query for Laptops and Desktops */
//  @media (min-width: 1025px) and (max-width: 1280px){
//  }
//
//  /* Media Query for Large screens */
//  @media (min-width: 1281px) {
//  }

