
.info-container {
  .info-image {
    display: flex;
    flex-direction: row;
    padding: 20px 0;

    div {
      flex: 1;

      img {
        width: 100%;
      }
    }
  }
}

.step-container {
  .step-info {
    .step-info-tabs {
      display: flex;
      flex-direction: row;

      button {
        flex: 1;
        flex-direction: column;
        padding: 12px 0;
        font-size: 21px;
        font-weight: 700;
        border-radius: 0;
      }
    }

    .step-info-img {
      margin-top: 20px;

      img {
        width: 100%;
      }
    }
  }
}

.qna-info-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 60px;
  background-color: white;

  .qna-info-desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 30px;
    font-size: 18px;
    color: #333333;

    &::before {
      display: block;
      content: "";
      width: 5px;
      height: 26px;
      margin-right: 10px;
      background-color: #1F95C8;
      border-radius: 10px;
    }
  }

  button {
    border-radius: 60px;
    span {
      font-size: 19px !important;
    }
  }
}

.knowledge-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 400px;

  &::after {
    flex: auto;
    content: "";
  }

  .knowledge-item {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 7px;
    border-radius: 20px;
    border: 1px solid #dddddd;
    overflow: hidden;
    aspect-ratio: 1.11;
    cursor: pointer;

    img {
      width: 100%;
      aspect-ratio: 1.8;
      object-fit: cover;
    }

    .knowledge-item-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px;
    }

    .knowledge-title {
      max-height: 54px;
      font-weight: 700;
      font-size: 18px;
      color: #333333;
      overflow: hidden;
    }

    .knowledge-time {
      text-align: end;
      font-size: 12px;
      color: #666666;
    }
  }
}

.board-container {
  .board-top-info {
    padding: 20px;
    background-color: #e1e9f3;
    border-radius: 8px;
  }

  .board-search {
    margin-top: 30px;
    padding: 15px 10px;
    background-color: #eaedf0;

    .MuiSelect-select, .MuiInputBase-input {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: white;
    }
  }

  .board-qna-list {
    .header {
      background-color: #fffaee;
      border-top: 1px solid #dddddd;
    }

    .board-qna-item {
      display: flex;
      flex-direction: row;
      padding: 12px 0;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;

      div {
        text-align: center;
      }

      div:nth-child(1) {
        width: 100px;
      }

      div:nth-child(2) {
        width: 100px;
      }

      div:nth-child(3) {
        flex: 1;
      }

      div:nth-child(4) {
        width: 120px;
      }

      div:nth-child(5) {
        width: 120px;
      }

      .status {
        padding: 3px 10px;
        font-size: 14px;
        border: 1px solid #dddddd;

        &.finish {
          color: #1160AA;
          border: 1px solid #1160AA;
        }
      }
    }
  }
}

.law-info-container {
  .law-info-list {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    border-top: 1px solid #dddddd;

    .law-item {
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      border: 1px solid #dddddd;
      cursor: pointer;

      img {
        width: 180px;
        height: 158px;
        object-fit: cover;
      }

      .law-item-title {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }

      .law-item-content {
        font-size: 14px;
        color: #666666;
      }

      .law-item-time {
        font-size: 12px;
        color: #666666;
      }
    }
  }
}

.issue-view-content {
  text-align: center;

  img {
    max-width: 100%;
  }
}

.issue-view-buttons {
  button {
    width: 90px;

    svg {
      font-size: 14px;
    }
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;

  .search-input {
    margin-top: 45px;

    .MuiTextField-root {
      width: 100%;

      .MuiInputBase-root {
        border-radius: 60px;

        input {
          padding: 19px 35px;
          font-size: 21px;
        }
      }
    }
  }

  .search-result {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px 0;
    font-size: 16px;
    font-weight: 700;
    color: #888888;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 15px;
      cursor: pointer;
    }

    div {
      width: 2px;
      height: 15px;
      border-radius: 2px;
      background-color: #aaaaaa;
    }
  }

  .search-all-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    cursor: pointer;
  }

  .search-issue-item-img {
    height: 60px;
  }

  .qna-filter-items {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      white-space: nowrap;
    }
  }
}

@media (max-width: 767px) {
  .info-container {
    .info-image {
      flex-direction: column;
      padding: 0;

      div {
        margin: 0 0 30px 0 !important;
      }
    }
  }
  .step-container {
    padding-left: 0;
    padding-right: 0;

    .step-info {
      .step-info-tabs {
        button, button > span {
          font-size: 14px !important;
        }
      }
    }
  }
  .qna-info-bar {
    flex-direction: column;
    padding-top: 20px;
    border: none;
    border-radius: 5px;

    .qna-info-desc {
      margin-left: 0;
      font-size: 14px;
    }

    button {
      margin: 10px 0 20px !important;
      padding: 3px 22px;
    }
  }

  .knowledge-list {
    .knowledge-item {
      width: 46%;
      aspect-ratio: 0.9;
      .knowledge-title {
        height: 42px;
        font-size: 14px;
      }
      .knowledge-time {
        margin-top: 10px;
      }
    }
  }

  .law-info-container .law-info-list .law-item {
    img {
      width: 100px;
      height: 127px;
    }

    .law-item-content {
      height: 45px;
      overflow: hidden;
    }
  }

  .board-container {
    .board-search {
      & > div:first-child {
        margin-bottom: 10px;
      }

      .MuiSelect-select, .MuiInputBase-input, button {
        font-size: 14px;
      }

      button {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .board-qna-list {
      .board-qna-item {
        font-size: 14px !important;

        div:nth-child(1), div:nth-child(5) {
          display: none;
        }

        div:nth-child(2), div:nth-child(4) {
          width: 85px;
        }
      }
    }
  }
  .search-container {
    .search-result {
      font-size: 14px;
    }
  }
}