//login page
.login-content {
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0;

  .login-logo {
    width: 213px;
  }

  button {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .naver-sso-btn {
    margin-top: 50px;
    background-color: #03c75a;

    &:hover {
      background-color: #03a64b;
    }

    span {
      padding-top: 1px;
    }
  }
}

.third-login-container {
  min-height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: bold;
  color: #333333;
}

.signin-content {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0;
  font-size: 16px;
  color: #333333;
}

.home-container {
  .slide-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;

    &.prev {
      left: 18.75vw;
    }

    &.next {
      right: 18.75vw;
    }

    button {
      color: white !important;
    }
  }

  .home-content {
    max-width: 1300px;
    padding: 0 50px;
    margin: 60px auto;

    .home-bar {
      width: 40px;
      height: 5px;
      border-radius: 10px;
      background-color: #1160AA;
    }

    .MuiTextField-root {
      width: 100%;
      max-width: 700px;

      .MuiInputBase-root {
        border-radius: 30px;

        input {
          padding-left: 25px
        }
      }
    }

    button {
      border-radius: 30px;
    }

    .card-container {
      margin-top: 100px;
      gap: 20px;

      .home-card {
        flex: 1;
        //aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #dddddd;
        border-radius: 20px;
        overflow: hidden;

        img {
          width: 100%;
        }

        button {
          margin: 10px 0 25px;
        }
      }
    }

    .home-info-container {
      margin-top: 100px;

      & > div {
        flex: 1;
        overflow: hidden;
      }

      .link-card-container {
        display: flex;
        flex-direction: column;

        .link-card-line {
          display: flex;

          & > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            aspect-ratio: 2;
            cursor: pointer;

            span {
              margin-top: 10px;
              color: white;
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .home-footer {
    padding-top: 40px;
    background-color: #1f95c8;

    .home-footer-content {
      max-width: 1300px;
      padding: 0 50px;
      margin: 0 auto;

      .home-footer-img {
        width: 590px;
        height: 330px;
      }

      .home-footer-app {
        margin-left: 3rem;

        .home-footer-label {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 21px;
          color: #00204A;

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 26px;
            margin-right: 10px;
            border-radius: 10px;
            background-color: #00204A;
          }
        }

        .home-footer-desc {
          font-size: 16px;
          color: white;
        }

        .store-btn {
          width: 180px;
          height: 58px;
          margin-right: 10px;
          border-radius: 8px;
          background-color: white;

          img {
            width: 100px;
          }
        }
      }
    }
  }

  .home-logo-container {
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    padding: 0 50px;
    margin: 0 auto;

    & > div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border-right: 1px solid #dddddd;
      cursor: pointer;

      &:first-child {
        border-left: 1px solid #dddddd;
      }
    }

    img {
      width: 210px;
    }
  }
}

.site-phone-info {
  margin-left: 10px;

  .phone-card-container {
    & > div {
      gap: 5px;
    }

    .phone-card {
      flex: 1;
      max-height: 86px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      color: #3C516C;
      background-color: #e1e9f3;
      border-radius: 10px;
      aspect-ratio: 3.5;

      img {
        width: 45px;
        height: 45px;
      }
    }
  }

  .email-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #dddddd;

    button {
      width: 100%;
      padding: 12px 0;
      background-color: #1f95c8;
      border-radius: 5px;
      text-transform: none;
    }
  }
}

.reset-email-modal {
  width: 404px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  font-size: 16px;
  color: #333333;

  .email-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #f2855e;
    border-radius: 40px;
    color: white;

    svg {
      font-size: 40px;
    }
  }
}

.privacy-modal {
  max-width: 800px;
  width: 80vw;
  height: 80vh;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {
  .home-container {
    .home-content {
      margin: 20px;
      padding: 0;

      .card-container {
        margin-top: 40px;
      }

      .home-info-container {
        margin-top: 40px;

        .link-card-container {
          margin-bottom: 10px;

          .link-card-line > div {
            aspect-ratio: 1.3 !important;
          }
        }
      }
    }

    .home-footer {
      .home-footer-content {
        padding: 40px 20px 0;

        .home-footer-app {
          margin-left: 0;

          .home-footer-desc {
            font-size: 14px;
          }
        }

        .home-footer-img {
          width: 100%;
          height: auto;
          margin-top: 50px;
        }
      }
    }

    .home-logo-container {
      padding: 0;
    }

    .slide-arrow {

      &.prev {
        left: 0.75vw;
      }

      &.next {
        right: 0.75vw;
      }
    }
  }
  .site-phone-info {
    margin-left: 0;
  }
  .login-container {
    padding: 50px 30px 30px;
  }
  .privacy-modal {
    width: auto;
    height: 78vh;
    padding: -15px;
  }
}