
.page-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: black;

  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 30px;
    margin-right: 10px;
    background-color: #1f95c8;
    border-radius: 10px;
  }
}

.btn-underline {
  color: #888888;
  text-decoration-line: underline;
  cursor: pointer;
}

.side-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 186px;
  padding-top: 70px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;

  .side-menu-title {
    font-size: 22px;
  }

  .side-menu-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    border-top: 1px solid #dddddd;

    div {
      padding: 20px 24px;
      background-color: white;
      color: #333333;
      border-radius: 0;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;

      &.active {
        color: white;
        background-color: #1160aa;
      }
    }
  }
}

.breadcrumb {
  font-size: 12px;
  color: #333333;
}

.qna-view-component {
  .qna-title {
    font-size: 18px;
    font-weight: 800;
    color: #333333;
  }

  .qna-content {
    flex: 1;
  }

  .qna-answer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 40px;
    font-size: 16px;
    color: #333333;
    background-color: #f3f3f3;
    border-radius: 10px;

    img {
      width: 120px;
      margin-bottom: 15px;
    }
  }
}

.dialog-container {
  .MuiPaper-root {
    min-width: 322px;
    border-radius: 8px;

    .dialog-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 5px 8px 15px;
      font-size: 16px;
      color: #888888;
      border: 1px solid #dddddd;
    }

    .dialog-content {
      padding: 25px;

      input {
        padding: 10px 12px;
        font-size: 14px;
      }

      .MuiSelect-select {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
      }
    }

    .dialog-action {
      display: flex;
      flex-direction: row;

      button {
        flex: 1;
        padding: 10px 0;
        font-size: 16px;
        border-radius: 0;
      }

      .cancel-btn {
        color: #666666;
        background-color: #E1E6ED;
      }
    }
  }
}

@media (max-width: 767px) {
  .page-title {
    font-size: 18px;

    &::before {
      width: 4px;
      height: 25px;
    }
  }

  .side-menu {
    width: auto;
    align-items: flex-start;
    border: none;

    .side-menu-child {
      flex-direction: row;
      margin-top: 10px;
      font-size: 14px;
      border-top: none;
      border-left: 1px solid #dddddd;

      div {
        flex: 1;
        padding: 5px 0;
        text-align: center;
        border-top: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }
    }
  }
  .qna-view-component {
    .qna-answer {
      padding: 20px;
      font-size: 14px;
    }
    .qna-content {
      margin-top: 10px;
    }
  }
}