
.profile-line {
  display: flex;
  flex-direction: row;
  padding: 30px 0 20px;
  font-size: 16px;
  color: #555555;
  border-bottom: 1px solid #dddddd;

  & > :first-child {
    width: 130px;
  }
}

.company-line {
  padding: 25px 0 15px;
  font-size: 15px;
  border-bottom: 1px solid #dddddd;

  & > span {
    text-decoration-line: underline;
  }

  button {
    padding: 0;
    border-radius: 30px;
  }
}

.my-qna-history {
  min-height: 500px;
}
.qna-history-line {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 20px;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;

  .history-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;
    padding-right: 20px;

    .history-status {
      margin-bottom: 5px;
      padding: 0 7px;
      font-size: 14px;
      color: #888888;
      border: 1px solid #dddddd;

      &.finish {
        color: #1160aa;
        border-color: #1160aa;
      }
    }

    .history-title {
      display: flex;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }

    .history-content {
      width: 100%;
      display: block;
      font-size: 14px;
      color: #666666;
      overflow: hidden;
      min-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .history-time {
    font-size: 12px;
    color: #888888;
  }
}

.qna-edit-container {
  .qna-private {
    padding: 5px 10px;
    color: #00204A;
    background-color: #e1e9f3;
  }

  .qna-item-title {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }

  .qna-item-textarea {
    min-height: 120px;
    padding: 10px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    &:focus-visible {
      outline-color: #1160aa;
    }
  }
  .qna-files {
    display: flex;
    flex-direction: row;
    min-height: 80px;
    padding: 10px;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
  }
  .qna-file-desc {
    margin-left: 10px;
    font-size: 12px;
    color: #1F95C8;
  }
}

@media (max-width: 767px) {
  .profile-line {
    padding: 20px 0 10px;
    font-size: 14px;
  }
  .qna-edit-container {
    .qna-item-title {
      font-size: 14px;
    }
    .MuiFormControlLabel-label, input, .MuiSelect-select {
      font-size: 14px;
    }
  }
  .qna-history-line {
    .history-info {
      padding-right: 0;
    }
    .history-time {
      position: absolute;
      top: 19px;
      right: 10px;
    }
  }
}